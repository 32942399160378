<template>
	<div class="exchange-wrap">
		<NavHeader title="预约订单"></NavHeader>
		<div class="content">
			<div class="order-list-wrap">
				<div class="service-wrap">
					<div class="information-wrap" v-if="customer_service">
						<div class="avatar-wrap">
							<img :src="customer_service.service_avatar" />
						</div>
						<div class="name-wrap">
							<div class="name">
								{{customer_service.service_nickname}}
								<img
									src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/fengche.svg" />
							</div>
							<div class="level">品牌顾问</div>
						</div>
						<div class="iconfont_program iconzu3" @click="serviceUrl"></div>
						<a class="iconfont_program iconzu2" :href="'tel://'+customer_service.service_mobile"></a>
					</div>
					<div class="description">“支付意向金即可完成预订，在预订期间，意向金随时可退，7个工作日内到账。”</div>
				</div>
				<van-tabs v-model="active" color="#00d596" title-active-color="#00d596" @click="tabsClick">
					<van-tab title="预约订单" name="order"></van-tab>
					<van-tab title="我的意向" name="intention"></van-tab>
				</van-tabs>
				<div class="order-list"  v-if="orderList && orderList.length>0">
					<div class="order-item" v-for="(item,index) in orderList" :key="index">
						<img :src="item.order_products[0].image" mode="widthFix" />
						<div class="order-information">
							<div class="name">
								<div class="lable">产品信息：</div>
								<div class="value">
									<span>{{item.order_products[0].category_name}} |
										{{item.order_products[0].name}}</span>
									<span class="iconfont iconxiangyou"></span>
								</div>
							</div>
							<div class="name">
								<div class="lable">预估价：</div>
								<div class="value">
									<div>{{item.order_products[0].total}}</div>
								</div>
							</div>
						</div>
						<div class="status-wrap" :class="item.order_status_code">
							{{item.order_status_name}}
						</div>
					</div>
				</div>
				<div class="order-list" v-else>
				    <div class="order-item" v-for="(item,index) in intentionList" :key="index">
				      <img :src="item.image" mode="widthFix" />
				      <div class="order-information">
				        <div class="name">
				          <div class="value">
				            <i>{{item.zone_name}}</i>
				            <i v-if="item.city_name">-{{item.city_name}}</i>
				            <i v-if="item.county_name">-{{item.county_name}}</i>
				          </div>
				        </div>
				        <div class="name">
				          <div class="value">
				            <i>{{item.name}}</i>
				            <i>-{{item.telephone}}</i>	
				            <i v-if="item.email">-{{item.email}}</i>
				          </div>
				        </div>
						<div class="name">
							{{item.added_time}}
						</div>
				      </div>
					  <van-button round :color="item.is_project>0?'linear-gradient(90deg, #01CDF2 0%, #0FE993 100%)':'#909399'">{{item.botton_name}}</van-button> 
				    </div>
				</div>
				<van-pagination class="pagination" :total-items="total" v-model="page" :items-per-page="per_page"
					force-ellipses @change="change">
					<template #prev-text>
						<van-icon name="arrow-left" />
					</template>
					<template #next-text>
						<van-icon name="arrow" />
					</template>
				</van-pagination>
	
				<div v-if="total==0">
					<xy-not-tip message="暂无预约记录"></xy-not-tip>
				</div>


			</div>

		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	import NavHeader from '@/components/nav-header.vue'
	import Footer from '@/components/foot2022.vue'
	import utils from '@/common/utils'
	import XyNotTip from '@/components/xy-not-tip.vue'
	export default {
		name: "appointment",
		data() {
			return {
				orderList: [],
				intentionList:[],
				per_page: 3,
				page: 1,
				active: '',
				total:0,
			}
		},
		components: {
			NavHeader,
			Footer,
			XyNotTip
		},
		computed: {
			...mapState(['user_info', 'houseApi', 'customer_service','ocApi']),
		},
		watch: {
			active: function(e) {
				if(e){
					this.dealData();
				}
			},
			'$route': {
				handler: function(to) {
					if (to.name == "appointment" && to.params.tagName) {
						this.active=to.params.tagName;
					}
				},
				immediate: true
			},
		},
		methods: {
			tabsClick(name, title){
				this.page=1;
			},
			dealData(){
				if(this.active=='intention'){
					this.orderList=[];
					this.getCollectionList();
				}else{
					this.intentionList=[];
					this.getOrderList();
				}
			},
			change(e) {
				this.page = e;
				this.dealData();
			},
			serviceUrl: utils.debounce(function() {
				window.location.href = this.customer_service.url
			}, 200, true),
			getOrderList: function() {
				this.$axios.get(this.houseApi + 'house/orders', {
						params: {
							telephone: this.user_info.telephone,
							order_type: 2,
							per_page: this.per_page,
							page: this.page
						}
					})
					.then(res => {
						this.orderList=res.data.data;
						this.total=res.data.total;
					})
					.catch(error => {
						window.console.log(error)
					});
			},
			getCollectionList: function() {
				this.$axios.get(this.ocApi + 'intentionCollection/list', {
						params: {
							per_page:this.per_page,
							page:this.page
						}
					})
					.then(res => {
						this.intentionList = res.data.data.data;
						this.total = res.data.data.total;
					})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.van-pagination {
		 justify-content: center;
		 margin-bottom: 20px;
		:deep(.van-pagination__next) {
			flex: none;
			padding: 0;
		}
		:deep(.van-pagination__item) {
		   color: #666666;
		   height: 40px;
		   width: 40px;
		   min-width: 40px;
		}
		:deep(.van-pagination__prev){
			flex: none;
			padding: 0;
		}
	
		:deep(.van-pagination__item--active) {
			background-color: #666666;
			color: #FFFFFF;
		}
	
	}
	
	.content {
		padding-top: 46px;
	}

	.order-list-wrap {
		width: 100%;
		padding: 0 20px;
		box-sizing: border-box;
	}

	.service-wrap {
		width: 100%;
		padding: 15px 20px;
		box-sizing: border-box;
		box-shadow: 0px 3px 5px 0px rgba(221, 221, 221, 0.34);
	}

	.service-wrap .information-wrap {
		display: flex;
		align-items: center;
	}

	.service-wrap .information-wrap .avatar-wrap {
		width: 50px;
		height: 50px;
		border-radius: 100%;
		overflow: hidden;
	}

	.service-wrap .information-wrap .avatar-wrap img {
		width: 100%;
	}

	.service-wrap .information-wrap .name-wrap {
		margin-left: 16px;
		flex: 1;
	}

	.service-wrap .information-wrap .name-wrap .name {
		font-size: 20px;
		color: #474D65;
		display: flex;
		align-items: center;
	}

	.service-wrap .information-wrap .name-wrap .name img {
		width: 16px;
		margin-left: 3px;
	}

	.service-wrap .information-wrap .name-wrap .level {
		font-size: 12px;
		color: #7D8090;
		margin-top: 7px;
	}

	.service-wrap .information-wrap .iconzu3 {
		font-size: 36px;
		color: #818392;
	}

	.service-wrap .information-wrap .iconzu2 {
		font-size: 36px;
		color: #818392;
		margin-left: 15px;
	}

	.service-wrap .description {
		width: 100%;
		padding: 7px 15px;
		box-sizing: border-box;
		background-color: #FAFAFA;
		border-radius: 6px;
		font-size: 12px;
		color: #7D8090;
		margin-top: 15px;
	}

	.order-list {
		margin-top: 15px;
	}

	.order-list .order-item {
		width: 100%;
		margin-bottom: 15px;
		position: relative;
		box-shadow: 0px 3px 5px 0px rgba(221, 221, 221, 0.34);
	}

	.order-list .order-item .status-wrap {
		position: absolute;
		right: 0;
		top: 0;
		padding: 7px 13px;
		border-radius: 0px 2px 0px 6px;
		font-size: 12px;
		color: #ffffff;
	}

	.order-list .order-item .status-wrap.paid {
		background: linear-gradient(90deg, rgba(15, 233, 145, 1), rgba(1, 204, 244, 1));
	}

	.order-list .order-item .status-wrap.unpaid {
		background: linear-gradient(90deg, rgba(248, 87, 166, 1), rgba(255, 88, 88, 1));
	}

	.order-list .order-item .status-wrap.cancelled {
		background: linear-gradient(90deg, rgba(96, 108, 136, 1), rgba(63, 76, 107, 1));
	}

	.order-list .order-item .status-wrap.refunded {
		background: linear-gradient(90deg, rgba(255, 128, 8, 1), rgba(255, 200, 55, 1));
	}

	.order-list .order-item>img {
		width: 100%;
	}

	.order-list .order-item>.order-information {
		width: 100%;
		padding: 10px 20px 23px;
		box-sizing: border-box;
		position: relative;
	}

	.order-list .order-item>.order-information .name {
		width: 100%;
		display: flex;
		justify-content: space-between;
		font-size: 14px;
		color: #7D8190;
		margin-top: 8px;
	}

	.order-list .order-item>.order-information .name .lable {
		width: 85px;
	}

	.order-list .order-item>.order-information .name .value .iconfont {
		font-size: 13px;
	}
	.van-button{
		position: absolute;
		bottom: 15px;
		right: 15px;
		font-size: 12px;
		height: 32px;
		width: 25%;
	}
</style>